<template>
  <!--value,  margin,  size,  render-as = 'canvas' | 'svg',   level = 'L' | 'M' | 'Q' | 'H' ,  background,  foreground-->
  <qrcode-vue
    :value="value"
    :size="size"
    level="H"
    render-as="canvas"
  />
</template>
<script>
  import QrcodeVue from 'qrcode.vue'

  export default {
    components: {
      QrcodeVue,
    },
    props: ['value', 'size'],
  }
</script>
